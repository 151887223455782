import { React, useState, useContext } from "react";
import Header from "../../Component/Header/Header"
import Adress from "../Sidebars/Adres";
import Footer from "../../Component/Footer/Footer";
import { Link } from "react-router-dom";
import MenyServis from "../../Component/Pages/Services/MenyServis";
import { MyContext } from '../MyContext';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

const text = {
    textAlign: 'right'
}

function Gallery() {


    const name = useContext(MyContext);

    

    return (
        <body>
            <Header />

            <div class="container sections-wrapper">
                <div class="row">
                    <div class="primary col-md-8 col-sm-12 col-xs-12">
                        <section class="about section">
                            <div class="section-inner">
                                <h2 class="heading">Фото Галерея</h2>
                                <ImageGallery items={name} />
                                <hr />
                                <Link class="btn btn-cta-secondary" to={`/`}>
                                    Вернуться на главную
                                </Link>
                            </div>

                        </section>
                    </div>
                    <div class="secondary col-md-4 col-sm-12 col-xs-12">
                        <Adress />
                        <MenyServis />

                    </div>
                </div>
            </div>

            <Footer />
        </body>
    );
}
export default Gallery;
