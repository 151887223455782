
function TrebovanieFrezernayaRezka () {
    return (
        <aside class="blog aside section">
            <div class="section-inner">
                <h2 class="heading">Требование к файлам</h2>
               <ul>
                    <li>CorelDraw не старше x3 версии</li>
                    <li>Масштаб 1:1</li>
                    <li>Все символы и текст должны быть переведены в кривые</li>
                    <li>Расстояние между отдельными объектами минимально 10 мм</li>
               </ul>
            </div>
        </aside>


);
}
export default TrebovanieFrezernayaRezka;
