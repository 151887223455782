
import { React, useState } from 'react';
import uuid from 'react-uuid';
import { Link } from 'react-router-dom';


function id() {
    return uuid();
}

const Images = [
    {
        id: id(),
        title: 'Уголок покупателя',
        url: '/img/galery'
    },
  
];

var monthArray = ['/glass/glass-', '/metal/metal-', '/papper/Papper-', '/plastic/plastic-', '/raznoe/raznoe-', '/tree/tree-'];
var randomElement = monthArray[Math.floor(Math.random() * monthArray.length)];

// Function to generate random number
function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
}


// Function call


function PhotoGalleryPreview (){

    const [image, setImage] = useState(Images);

  
    const result = image.map(img => {
     
        return <ul class="list-unstyled" key={img.id}>
       
            <img class="img-responsive project-image" src={img.url + randomElement + randomNumber(1, 4) +'.png'} alt="случайное фото из галереи АсАн" />
            </ul >

        })


    return (
<aside class="blog aside section">
    <div class="section-inner">
        <h2 class="heading">Наши работы:</h2>
               {result}
            
                <hr />
               <p>Перейдите по ссылке, чтобы увидеть больше фотографий из нашей галереи.</p> 
          
                
                <Link className="btn btn-cta-secondary btn-follow" to={'/Gallery/'} ><i className="fa fa-chevron-right"></i>Увидеть больше</Link>

    </div>
</aside>
    )
}



export default PhotoGalleryPreview;