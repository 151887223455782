


export const COLUMNS = [
   
    {
        Header: 'Материал',
        accessor: 'Материал',
    },
    {
        Header: 'От 10-50 шт',
        accessor: 'От 10-50 шт',
    },
    {
        Header: 'От 50 - 100 шт',
        accessor: 'От 50 - 100 шт',
    },
    {
        Header: 'От 100 - 500 шт',
        accessor: 'От 100 - 500 шт',
    },
];