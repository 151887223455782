
function TrebovanieLazernayaRezka() {
    return (
        <aside class="blog aside section">
            <div class="section-inner">
                <h2 class="heading">Требование к файлам</h2>
                <ul>
                    <li>CorelDraw не старше x3 версии</li>
                    <li>Масштаб 1:1</li>
                    <li>Все символы и текст должны быть переведены в кривые</li>
                    <li>Растровые изображения не допускаются. Толщина кривых – «0» или "Hair line". Недопустимо формировать изображение заданием толщины кривых.</li>
                    <li>Недопустимо наложение слоёв</li>
                    <li>Расстояние между отдельными объектами минимально 1мм</li>
                </ul>
            </div>
        </aside>


    );
}
export default TrebovanieLazernayaRezka;
