import { React, useState, useContext, useEffect } from "react";
import "../../../css/App.css";
import Header from "../../../Header/Header";
import Adress from "../../../Sidebars/Adres";
import Footer from "../../../Footer/Footer";
import IMG from "../../../Img/4844385a8fcc11efaa6442315e171248 (1).jpg";
import StoimostFrezerovki from "./StoimostFrezerovki";
import MenyServis from "../MenyServis";
import TrebovanieFrezernayaRezka from "./TrebovanieFrezernayaRezka";
import MenuProducts from "../../Products/MenuProducts";
import { MyContext } from '../../../MyContext';

function FrezernayaRezka() {

  const name = useContext(MyContext);

  useEffect(() => {
  document.title = "АсАн - " + name[1].title;
  },);

  return (
    <>
      <body>
        <Header />

        <div className="container sections-wrapper">
          <div className="row">
            <div className="primary col-md-8 col-sm-12 col-xs-12">
              <section className="about section">
                <div className="section-inner">
                  <h2 className="heading">{name[1].title}</h2>
                  <div className="content">
                    <img
                      className="img-responsive project-image"
                      src={IMG}
                      alt="project name"
                    />
                    <hr />
                    <p>
                      Уважаемые клиенты и гости нашего сайта! Рады сообщить Вам,
                      что в нашей мастерской пополнился автопарк и появилось
                      новое направление - фрезерная резка материалов. Размер
                      рабочего поля станка 2000*3000*150 позволяет обрабатывать
                      большинство материалов, представленных на рекламном рынке,
                      в мебельном производстве, строительстве. Мы предлагаем:
                      низкие цены, высокое качество, быстрое выполнение заказа.
                      Ниже представлен перечень обрабатываемых материалов:
                    </p>
                  </div>
                </div>
              </section>
              <StoimostFrezerovki />
            </div>
            <div className="secondary col-md-4 col-sm-12 col-xs-12">
              <Adress />
              <TrebovanieFrezernayaRezka />
              <MenyServis />
              <MenuProducts />
            </div>
          </div>
        </div>
        <Footer />
      </body>
    </>
  );
}
export default FrezernayaRezka;
