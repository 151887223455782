function InfoLazernayaRezka() {
    return (
        <aside class="blog aside section">
            <div class="section-inner">
                <h2 class="heading">Информация для заказчиков</h2>
                <ul>
                    <li>В стоимость лазерной резки не включена стоимость материала.</li>
                    <li>Минимальная сумма заказа составляет 1000 рублей.</li>
                    <li>Выполнение срочного заказа +50%</li>
                </ul>
            </div>
        </aside>


    );
}
export default InfoLazernayaRezka;
