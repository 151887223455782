



function Requisites (){
    return(
        <aside class="blog aside section">
            <div class="section-inner">
                <h2 class="heading">Наши реквизиты</h2>
                <p>ОГРНИП: 315619200002926 от 13.05.2015</p>
                <p>ИНН: 614209056125</p>
                <div id="rss-feeds" class="content">

                </div>
            </div>
        </aside>
    )
}



export default Requisites;