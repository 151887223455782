import "../../../css/App.css";
import React from "react";
import Header from "../../../Header/Header";
import Adress from "../../../Sidebars/Adres";
import Footer from "../../../Footer/Footer";
import IMG from "../../../Img/LazernayaRezka.png";
import MenyServis from "../MenyServis";
import TrebovanieLazernayaRezka from "./TrebovanieLazernayaRezka";
import InfoLazernayaRezka from "./InfoLazernayaRezka";
import StoimostLazernojRezki from "./StoimostLazernojRezki";
import MenuProducts from "../../Products/MenuProducts";

function LazernayaRezka() {
  return (
    <body>
      <Header />

      <div class="container sections-wrapper">
        <div class="row">
          <div class="primary col-md-8 col-sm-12 col-xs-12">
            <section class="about section">
              <div class="section-inner">
                <h2 class="heading">Лазерная резка</h2>
                <div class="content">
                  <img
                    class="img-responsive project-image"
                    src={IMG}
                    alt="project name"
                  />
                  <hr />
                  <p>
                    Лазерно-гравировальная мастерская АсАн предоставляетуслуги
                    по лазерной резке неметаллических материалов таких как:
                    бумага, картон (гофрокартон), пенокартон, поролон, изолон,
                    фанера, кожа толщиной до 15 мм по доступным ценам в короткие
                    сроки. Выполняем срочные заказы (изготовление при Вас) на
                    лазерную резку. Для постоянных клиентов предусмотрены
                    скидки.
                  </p>
                </div>
              </div>
            </section>
            <StoimostLazernojRezki />
          </div>
          <div class="secondary col-md-4 col-sm-12 col-xs-12">
            <Adress />
            <TrebovanieLazernayaRezka />
            <InfoLazernayaRezka />
            <MenyServis />
            <MenuProducts />
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
}
export default LazernayaRezka;
