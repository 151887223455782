/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { MyContext } from '../../MyContext';




function Articles() {
    const name = useContext(MyContext);

    const [articles, setArticle] = useState(name.slice(0, 3));
  
    useEffect(() => {
        document.title = "АсАн - Полезные статьи мастерской АсАн"
    }, []);
    const result = articles.map(articl => {
       
        return <div class="item" key={articl.id}>
            <h3 class="title">{articl.title}</h3>
            <p class="summary">{articl.preview}</p>
            <Link className="more-link" to={'/FullArticles/' + articl.url}><i className="fa fa-external-link"></i>Узнать больше</Link>
        </div>
          
    })

    return (
        <section class="projects section">
            <div class="section-inner">
                <h2 class="heading">Полезные статьи мастерской АсАн</h2>
                <div class="content">
                    {result} 
                    <Link className="btn btn-cta-secondary" to={'/FullArticles/'} ><i className="fa fa-chevron-right"></i>Все статьи</Link>
                   

                </div>
            </div>
        </section>
    )
}
export default Articles;
