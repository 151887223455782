import "../../../css/App.css";
import React from "react";
import Header from "../../../Header/Header";
import Adress from "../../../Sidebars/Adres";
import Footer from "../../../Footer/Footer";
import IMG from "../../../Img/LazernayaGravirovka.png";
import MenyServis from "../MenyServis";
import InfoLazernayaGravirovka from "./InfoLazernayaGravirovka";
import StoimostLazernayaGravirovka from "./StoimostLazernayaGravirovka";
import MenuProducts from "../../Products/MenuProducts";

function LazernayaGravirovka() {
    return (
        <body>
            <Header />

            <div class="container sections-wrapper">
                <div class="row">
                    <div class="primary col-md-8 col-sm-12 col-xs-12">
                        <section class="about section">
                            <div class="section-inner">
                                <h2 class="heading">Лазерная Гравировка</h2>
                                <div class="content">
                                    <img
                                        class="img-responsive project-image"
                                        src={IMG}
                                        alt="project name"
                                    />
                                    <hr />
                                    <p>
                                        Лазерно-гравировальная мастерская АсАн предлагет Вам услуги по лазерной гравировке: на дереве, силакатном стекле, бумаге, бокалах, бутылках, брелках, пластике. Изготавливаем атрибутику для свадебных торжеств, юбилеев, общественных мероприятий, праздников
                                    </p>
                                </div>
                            </div>
                        </section>
                        <StoimostLazernayaGravirovka />
                    </div>
                    <div class="secondary col-md-4 col-sm-12 col-xs-12">
                        <Adress />
                        <InfoLazernayaGravirovka />
                        <MenyServis />
                        <MenuProducts />
                    </div>
                </div>
            </div>

            <Footer />
        </body>
    );
}
export default LazernayaGravirovka;
