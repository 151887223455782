
function Intro (){

    return (
        <section class="about section">
    <div class="section-inner">
                <h2 class="heading">Гравировальная мастерская АсАн</h2>
        <div class="content">
                    <p>Мы рады приветствовать Вас на странице лазерно-гравировальной мастерской АсАн.</p>
                    <p>Мы занимаемся оформлением мест продаж. Предоставляем услуги по лазерной резке неметаллических материалов таких как: бумага, картон (гофрокартон), пенокартон, поролон, изолон, фанера, кожа толщиной до 15 мм. Выполняем гравировку на бумаге, дереве, металле, силикатном стекле, бутылках, бокалах. Выполняем фрезерную резку.</p>
                    <p>Мы работаем для Вас и принимаем заказы по всей России и странам ближнего и дальнего зарубежья. Доставка осуществляется транспортными компаниями. В кротчайщие сроки и по низкой цене, выполним ваш заказ. Мы ценим Ваше время!"</p>
        </div>
    </div>
</section>
    )
}
export default Intro;
