function TrebovanieLazernayaRezka() {
  return (
    <aside class="blog aside section">
      <div class="section-inner">
        <h2 class="heading">Требование к файлам</h2>
        <ul>
          <li>Графический редактор формата: Adobe Illustrator CS3</li>
          <li>Corel Draw не старше X3 версии</li>
          <li>Масштаб 1:1</li>
          <li>
            Шрифты и другие объекты маета должны быть быть переведены в
            «кривые», заливка исключена
          </li>
          <li>Ширина наклейки не более 1240 мм</li>
          <li>
            Макет не должен содержать незамкнутые и пересекающиеся контуры
          </li>
          <li>Высота шрифтов не менее 10 мм</li>
          <li>
            При размещении одинаковых элементов на листе , зазор между ними не
            менее 4 м
          </li>
          <li>
            Компонуя материалы для плоттерной обработки, учитывайте размеры
            изделия, на который будет наноситься изображение, ширину рабочего
            поля, оставляйте припуски для роликов-держателей плоттера (по 15 мм
            с боков, по 75 мм сверху и снизу)
          </li>
        </ul>
      </div>
    </aside>
  );
}
export default TrebovanieLazernayaRezka;
