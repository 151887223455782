function InfoLazernayaRezka() {
    return (
        <aside class="blog aside section">
            <div class="section-inner">
                <h2 class="heading">Информация для заказчиков</h2>
                <ul>
                    <li>Наценка на круговую лазерную гравировку + 30%</li>
                </ul>
            </div>
        </aside>


    );
}
export default InfoLazernayaRezka;
