

function Adress () {

    return (
       
        <aside class="info aside section">
    <div class="section-inner">
                <h2 class="heading sr-only">Основная информация</h2>
        <div class="content">
            <ul class="list-unstyled">
                        <li><i class="fa fa-map-marker"></i><span class="sr-only">Адрес:</span>город Белая Калитва, Кольцова 25 А</li>
                        <li><i class="fa fa-envelope-o"></i><span class="sr-only">Email:</span><a href="mailto:laser@asan-pro.ru?subject=Письмо с сайта asan-pro.ru">laser@asan-pro.ru</a></li>
                        <li><i class="fa fa-phone"></i><span class="sr-only">Городской телефон:</span><a href="tel:+78635299650">+7 (863) 529 96 50</a></li>
                        <li><i class="fa fa-mobile"></i><span class="sr-only">Мобильный телефон:</span><a href="tel:+79514957354">+7 (951) 49 57 354</a></li>
                        <li><i class="fa fa-calendar"></i><span class="sr-only">Режим работы:</span>Понедельник - пятница: 10:00 - 19:00</li>
                        <li><i class="fa fa-calendar " aria-hidden="true"></i><span class="sr-only">Режим работы:</span>Суббота: 10:00 - 18:00</li>
            </ul>
        </div>
    </div>
</aside>

    )
    
}

export default Adress;

