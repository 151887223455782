/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useState } from 'react';
import uuid from 'react-uuid';
import { Outlet, Link } from 'react-router-dom';


function id() {
    return uuid();
}

const  menyservis = [
    {
        id: id(),
        title: 'Фрезерная резка',
        url: '/FrezernayaRezka/'
    },
    {
        id: id(),
        title: 'Лазерная резка',
        url: '/LazernayaRezka/'
    },
    {
        id: id(),
        title: 'Лазерная гравировка',
        url: '/LazernayaGravirovka/'
    },
    {
        id: id(),
        title: 'Плотерная резка',
        url: '/PloternayaRezka/'
    },
    {
        id: id(),
        title: 'Ремонт станков с ЧПУ',
        url: '/RemontStankovChPU/'
    }
];


function MenyServis() {

    const [meny, setMeny] = useState(menyservis);

    const result = meny.map(menys => {

        return <ul class="list-unstyled" key={menys.id}>
            <li> <i class="fa fa-external-link"></i>  <Link to={menys.url}>{menys.title}</Link></li>

           {/* // <li ><a href={produ.url} target="_blank" rel="noreferrer"><i class="fa fa-external-link"></i> {produ.title} </a></li> */}
        </ul >

    })




    return (
        <aside class="credits aside section">
            <div class="section-inner">
                <h2 class="heading">Наши услуги</h2>
                <div class="content">

                    {result}
                    <hr />
                </div>
            </div>
        </aside>
    )
}
export default MenyServis;

