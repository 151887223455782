
import { React, useState } from 'react';
import uuid from 'react-uuid';


function id() {
    return uuid();
}

const partners = [
    {
        id: id(),
        title: 'Сегодня компания DNS– один из лидеров цифрового ритейла России!',
        img: 'img/ДНС.png',
        alt: 'ДНС Ритейл'
    },
    {
        id: id(),
        title: 'Компания «Мэйдж», основана в 1993 году и уже более 20 лет успешно работает на рынке полиграфической продукции.',
        img: 'img/мейдж.png',
        alt: '«Мэйдж»'
    },
    {
        id: id(),
        title: 'Команда настоящих профессионалов своего дела: дизайнеры, конструкторы, менеджеры, специалисты производства и монтажа.',
        img: 'img/DizArti.png',
        alt: 'DizArti'
    },
    {
        id: id(),
        title: 'Экономьте ваше время, усилия и средства, используя современное технологичное оборудование с командой cut4you.',
        img: 'img/cut.png',
        alt: 'cut4you'
    }
];





function Partners (){

    const [partner, setPartner] = useState(partners);

    const result = partner.map(part => {

        return <div class="item">
            <img class="profile-image img-responsive " src={part.img} alt={part.alt} />
            <h4 class="university">{part.title}</h4>
        </div>

    })

    return (
        <aside class="education aside section">
            <div class="section-inner">
                <h2 class="heading">Наши друзья и партнёры</h2>
                <div class="content">
                    {result}
                 
                </div>
            </div>
        </aside>
    )
}
export default Partners;