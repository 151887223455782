


export const COLUMNS = [
   
    {
        Header: 'Материал',
        accessor: 'Материал',
    },
    {
        Header: 'Толщина материала (мм)',
        accessor: 'Толщина материала (мм)',
    },
    {
        Header: 'До 500 п.м. (р.)',
        accessor: 'До 500',
    },
    {
        Header: 'До 1000 п.м.(р.)',
        accessor: 'До 1000',
    },
    {
        Header: 'От 1000 п.м. (р.)',
        accessor: 'От 1000',
    },
];