import "../../../css/App.css";
import React from "react";
import { Outlet, Link } from 'react-router-dom';
import Header from "../../../Header/Header";
import Adress from "../../../Sidebars/Adres";
import Footer from "../../../Footer/Footer";
import IMG from "../../../Img/RemontStankovChPU.png";
import MenyServis from "../MenyServis";
import Products from '../../Products/MenuProducts';


function RemontStankovChPU() {
    return (
        <body>
            <Header />

            <div class="container sections-wrapper">
                <div class="row">
                    <div class="primary col-md-8 col-sm-12 col-xs-12">
                        <section class="about section">
                            <div class="section-inner">
                                <h2 class="heading">Ремонт и диагностика станков с со2 излучателем и твердотельным лазером</h2>
                                <div class="content">
                                    <img
                                        class="img-responsive project-image"
                                        src={IMG}
                                        alt="PloternayaRezka"
                                    />
                                    <hr />
                                    <p>
                                        Лазерно-гравировальная мастерская АсАн предоставляет комплекс услуг по диагностике неисправностей фрезерно-гравировальных станков с ЧПУ и лазерно-гравировальных машин(СО2), твердотельных лазеров(кафедра лазерных машин). Подробную информацию об услуге вы можете уточнить у менеджеров нашей компании по тел.: 8-863-529-96-50
                                    </p>
                                    <p>Лазерно-гравировальные машины на CO2 и станки на твердотельных лазерах — чрезвычайно сложное оборудование, требующее внимательного отношения к эксплуатации. Если его обслуживание и ремонт доверять непрофессионалам, срок его работы может сократиться в разы. Учитывая цену такого оборудования, можно представить сумму убытков, которые понесет в результате такого подхода предприятие-владелец подобных машин. </p>
                                    <p>Диагностика станков с ЧПУ, основанных на лазерных технологиях, включает диагностику специального высоковольтного блока питания, драйвера приводов, поиск поврежденной электронной платы. В нашей стране найти плату для замены очень непросто. </p>
                                    <p>Ремонт и настройка лазерных станков с ЧПУ часто связаны с заменой линз, зеркал, лазерной трубки, ремонтом и подключением высоковольтного блока питания, заменой высоковольтного провода. Все перечисленные этапы представляют собой очень тонкую работу, ошибиться в которой может означать безвозвратную потерю высокотехнологичного устройства. </p>
                                    <p>Ошибки в ремонте и настройке также могут привести к пожару. Вызов мастера для негарантийного ремонта после такого события и восстановление сгоревшего оборудования обойдутся фирме во многие десятки тысяч рублей. </p>
                                    <p>Ремонт ЧПУ завершается юстировкой оптики. Эта процедура требует от мастера навыков, которые приобретаются лишь длительным опытом. </p>
                                    <p>Работа по ремонту лазерного станка требует глубокого знания лазерной технологии, всех многочисленных ноу-хау, которые применяют при изготовлении подобного оборудования зарубежные производители. Существует мнение, что с этим справятся любые квалифицированные специалисты по электротехнике; достаточно лишь дать им ознакомиться с прилагаемой к станку документацией. </p>
                                    <p>Однако в настоящее время производители перестали снабжать оборудование объемистым пакетом технических документов. Мотивируется это тем, что современные станки такого рода настолько совершенны, что при грамотном обслуживании поломаться просто не могут. В сложных же случаях в Европе и США всегда придет на помощь компания, выпустившая станок.</p>
                                    <p>Диагностика, переналадка, профилактический ремонт даже идеально работающего оборудования подобного рода должны производить не реже, чем раз в полгода. В нашей компании вы можете получить помощь высококвалифицированных специалистов в данной области.</p>
                                </div>
                                <hr />
                                <Link class="btn btn-cta-secondary" to={`/`}>Вернуться на главную</Link>
                            </div>
                        </section>

                    </div>
                    <div class="secondary col-md-4 col-sm-12 col-xs-12">
                        <Adress />
                        <MenyServis />
                        <Products />
                    </div>
                </div>
            </div>

            <Footer />
        </body>
    );
}
export default RemontStankovChPU;
