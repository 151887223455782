import { React, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import Adress from "../../Sidebars/Adres";
import MenuProducts from "./MenuProducts";
import MenyServis from "../Services/MenyServis";
import { useContext } from 'react';
import { MyContext } from '../../MyContext';


function InformacionnyeCtendy() {
  const name = useContext(MyContext);
  
  const [notes, setNotes] = useState(name);

  const result = notes.map((note) => {
    return (
      <><div className="item row">
            <a className="col-md-4 col-sm-4 col-xs-12" key={note.id}>
                <img
                    class="img-responsive project-image"
                    src={note.src}
                    alt={note.alt} />
            </a>
            <div className="desc col-md-8 col-sm-8 col-xs-12">
                <h3 className="title">{note.title}</h3>
                <p>{note.text}</p>
<br />
                <span class="label label-theme">Цена: {note.price} руб.</span>
            </div>
        </div><hr /></>
    );
  });

  return (
    <div>
      <body>
        <Header />
        <div class="container sections-wrapper">
          <div class="row">
            <div class="primary col-md-8 col-sm-12 col-xs-12">
              <section class="latest section">
                <div class="section-inner">
                  <h2 class="heading">Информационные стенды</h2>
                  <div class="content">{result}</div>

                
                  <Link class="btn btn-cta-secondary" to={`/`}>
                    Вернуться на главную
                  </Link>
                </div>
              </section>
            </div>
            <div class="secondary col-md-4 col-sm-12 col-xs-12">
              <Adress />
              <MenuProducts />
              <MenyServis />
            </div>
          </div>
        </div>

        <Footer />
      </body>
    </div>
  );
}
export default InformacionnyeCtendy;
