import "../../../css/App.css";
import React from "react";
import Header from "../../../Header/Header";
import Adress from "../../../Sidebars/Adres";
import Footer from "../../../Footer/Footer";
import IMG from "../../../Img/PloternayaRezka.png";
import MenyServis from "../MenyServis";
import TrebovaniePloternayaRezka from "./TrebovaniePloternayaRezka";
import StoimostPloternayaRezka from "./StoimostPloternayaRezka";
import MenuProducts from "../../Products/MenuProducts";

function PloternayaRezka() {
    return (
        <body>
            <Header />

            <div class="container sections-wrapper">
                <div class="row">
                    <div class="primary col-md-8 col-sm-12 col-xs-12">
                        <section class="about section">
                            <div class="section-inner">
                                <h2 class="heading">Плотерная резка</h2>
                                <div class="content">
                                    <img
                                        class="img-responsive project-image"
                                        src={IMG}
                                        alt="PloternayaRezka"
                                    />
                                    <hr />
                                    <p>
                                        Лазерно-гравировальная мастерская АсАн предоставляет услуги по плотерной резке. Это самый современный и практичный способ нанесения рисунка на информационные стенды, вывески, рекламные щиты и тд. Технология, которая позволяет вырезать из различных материалов элементы определенной формы.
                                    </p>
                                </div>
                            </div>
                        </section>
                      <StoimostPloternayaRezka />
                    </div>
                    <div class="secondary col-md-4 col-sm-12 col-xs-12">
                        <Adress />
                        <TrebovaniePloternayaRezka />
                        <MenyServis />
                        <MenuProducts />
                    </div>
                </div>
            </div>

            <Footer />
        </body>
    );
}
export default PloternayaRezka;
