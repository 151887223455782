


export const COLUMNS = [
   
    {
        Header: 'Материал',
        accessor: 'Материал',
    },
    {
        Header: 'Толщина материала (мм)',
        accessor: 'Толщина материала (мм)',
    },
    {
        Header: 'До 100',
        accessor: 'До 100',
    },
    {
        Header: 'До 500',
        accessor: 'До 500',
    },
    {
        Header: 'Свыше 500',
        accessor: 'Свыше 500',
    },
];