import Logo from "../Img/logo.png";
import { Link } from 'react-router-dom';

function Header() {
    return (
        <>
            <header class="header">
                <div class="container">
                    <img class="profile-image img-responsive pull-left" src={Logo} alt="AcAн" />
                    <div class="profile-content pull-left">
                        <h1 class="name">Лазерно-Гравировальная мастерская</h1>
                        <h2 class="desc">Мы ценим ваше время!</h2>
                        <ul class="social list-inline">
                            <Link to={'/'} ><i class="fa fa-home" aria-hidden="true"></i></Link>
                            <li><a href="https://youtube.com/channel/UCy-WjrDSb5QhDzXKdIGvCzA?si=joXm-XdfzRbWAeVk" target="_blank"><i class="fa fa-youtube"></i></a></li>
                            <li><a href="mailto:laser@asan-pro.ru?subject=Письмо с сайта asan-pro.ru"><i class="fa fa-envelope-o" target="_blank"></i></a></li>
                            <li><a href="https://yandex.ru/maps/org/asan/157486193427/?ll=40.746108%2C48.192368&z=16" target="_blank"><i class="fa fa-map-marker"></i></a></li>
                          
                        </ul>
                    </div>
                 
                    <Link class="btn btn-cta-primary pull-right" to={'/Form/'} ><i class="fa fa-paper-plane"></i>Свяжитесь с нами</Link>
                </div>
            </header>
        </>
    );
}

export default Header;