


export const COLUMNS = [
   
    {
        Header: 'Формат материала',
        accessor: 'Формат материала',
    },
    {
        Header: 'До 50 м2',
        accessor: 'До 50 м2',
    },
    {
        Header: '50-100 м2', 
        accessor: '50-100 м2',
    },
    {
        Header: 'От 100 м2',
        accessor: 'От 100 м2',
    },
];