



function Footer (){

    function year (){
        return new Date().getFullYear()
    }

    return(
        <footer class="footer">
    <div class="container text-center">
                <small class="copyright">Лазерно-Гравировальная мастерская АсАн © 2013-{year()} <i class="fa fa-heart"></i> by <a href="http://asan-pro.ru" target="_blank">asan-pro.ru</a></small>
    </div>
</footer>
    )
}

export default Footer;