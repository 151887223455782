import { React, useState, useContext, useEffect } from "react";
import Header from "../../Header/Header";
import Adress from "../../Sidebars/Adres";
import Footer from "../../Footer/Footer";
import { Link } from "react-router-dom";
import MenuProducts from "../Products/MenuProducts";
import MenyServis from "../Services/MenyServis";
import { MyContext } from '../../MyContext';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import Delivery from "../../Sidebars/Delivery/Delivery";
import Requisites from "../../Sidebars/Requisites/Requisites";

const defaultState = {
    center: [48.17698, 40.80334],
    zoom: 2,
};


function Maps() {


    const name = useContext(MyContext);
    const [value, setValue] = useState(name);

    const result = value.map(val=> {

        return <Placemark geometry={val.coordinates} />

    })
    console.log(result)

    useEffect(() => {
        document.title = "АсАн - География наших клиентов" 
    },);



    return (
        <>
            <body>
                <Header />

                <div className="container sections-wrapper">
                    <div className="row">
                        <div className="primary col-md-8 col-sm-12 col-xs-12">
                            <section className="about section">
                                <div className="section-inner">
                                    <h2 className="heading">География наших клиентов</h2>
                                    <div className="content">
                                        <YMaps>
                                            <Map defaultState={defaultState} height={600} width="600">
                                                {result}
                                               
                                            </Map>
                                        </YMaps>
                                        <hr />
                                        <p>
                                            Наша лазерно-гравировальная мастерская выполняет заказы для своих клиентов по всей стране и странам ближнего зарубежья. Проектируем и выполняем заказы по чертежам и эскизам наших клиентов, согласовываем макеты и приступаем к производству. Транспортными компаниями мы доставляем готовую продукцию в оговоренные сроки нашим партнёрам. Ниже представлена интерактивная карта расположения наших клиентов, постоянно она пополняется новыми городами и странами, мы рады что вы выбрали нашу мастерскую для реализации своих проектов!
                                        </p>
                                    </div>
                                     <hr />
                                    <Link class="btn btn-cta-secondary" to={`/`}>
                                        Вернуться на главную
                                    </Link>
                                </div>
                               
                            </section>
                        </div>
                        <div className="secondary col-md-4 col-sm-12 col-xs-12">
                            <Adress />
                            <Delivery />
                         <Requisites />
                        </div>
                    </div>
                </div>
                <Footer />
            </body>
        </>
    );
}
export default Maps;
