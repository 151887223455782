import React, { Component } from "react";
import Input from "./components/Input";
import TextArea from "./components/TextArea";
import Button from "./components/Button";
import { toast, ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const notify = () => {
  toast.success("Ваше сообщение отправлено", {
    position: "top-center",
  });
};

class FormContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newUser: {
        name: "",
        email: "",
      },
    };
    this.handleTextArea = this.handleTextArea.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleFullName = this.handleFullName.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleClearForm = this.handleClearForm.bind(this);
    this.handleInput = this.handleInput.bind(this);
  }

  /* This lifecycle hook gets executed when the component mounts */

  handleFullName(e) {
    let value = e.target.value;
    this.setState(
      (prevState) => ({
        newUser: {
          ...prevState.newUser,
          name: value,
        },
      }),
      () => console.log(this.state.newUser)
    );
  }

  handleEmail(e) {
    let value = e.target.value;
    this.setState(
      (prevState) => ({
        newUser: {
          ...prevState.newUser,
          email: value,
        },
      }),
      () => console.log(this.state.newUser)
    );
  }

  handleInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    this.setState(
      (prevState) => ({
        newUser: {
          ...prevState.newUser,
          [name]: value,
        },
      }),
      () => console.log(this.state.newUser)
    );
  }

  handleTextArea(e) {
    console.log("Inside handleTextArea");
    let value = e.target.value;
    this.setState(
      (prevState) => ({
        newUser: {
          ...prevState.newUser,
          text: value,
        },
      }),
      () => console.log(this.state.newUser)
    );
  }

  handleFormSubmit(e) {
    e.preventDefault();
    let userData = this.state.newUser;

    fetch("https://client.asan-pro.ru/send-message", {
      method: "POST",
      body: JSON.stringify(userData),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((data) => {
        console.log("Successful" + data);
      });
    });
    notify();

    this.setState({
      newUser: {
        name: "",
        email: "",
        text: "",
      },
    });
  }

  handleClearForm(e) {
    e.preventDefault();
    this.setState({
      newUser: {
        name: "",
        email: "",
        text: "",
      },
    });
  }

  render() {
    return (
      <form className="container-fluid" onSubmit={this.handleFormSubmit}>
        <Input
          inputType={"text"}
          title={"Имя"}
          name={"name"}
          value={this.state.newUser.name}
          placeholder={"Ваше имя"}
          handleChange={this.handleInput}
        />{" "}
        {/* Name of the user */}
        <Input
          inputType={"text"}
          name={"email"}
          title={"E-email"}
          value={this.state.newUser.email}
          placeholder={"оставьте свой e-email"}
          handleChange={this.handleEmail}
        />{" "}
        <TextArea
          title={"Ваше сообщение."}
          rows={10}
          value={this.state.newUser.text}
          name={"currentPetInfo"}
          handleChange={this.handleTextArea}
          placeholder={"Напишите ваш запрос, пожелания, предложения"}
        />
        {/* Text you */}
        <Button
          action={this.handleFormSubmit}
          type={"primary"}
          title={"Отправить"}
          style={buttonStyle}
          onClick={notify}
        />{" "}
        {/*Submit */}
        <Button
          action={this.handleClearForm}
          type={"secondary"}
          title={"Очистить"}
          style={buttonStyle}
        />{" "}
        {/* Clear the form */}
        <ToastContainer transition={Slide} />
      </form>
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px",
};

export default FormContainer;
