
import { Outlet, Link } from 'react-router-dom';
import { useTable } from 'react-table'
import MOCK_DATA from './StoimostLazernayaGravirovka.json';
import { COLUMNS } from './Columns'
import React, { useMemo } from 'react'
import '../../../css/table.css'




function LazernayaGravirovka() {

    const columns = useMemo(() => COLUMNS, [])
    const data = useMemo(() => MOCK_DATA, [])

    const tableInstance = useTable({
        columns,
        data
    })
    const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } = tableInstance
    return (
        <section class="about section">
            <div class="section-inner">
                <h2 class="heading">Стоимость лазерной гравировки</h2>
                <div class="content">
                    <hr />
                    <h4 class="title">Лазерная гравировка изделий (плоская поверхность), руб./см2.</h4>
                    <table {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps()}>
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {rows.map(row => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}</td>
                                        })}
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                </div>
                <hr />
                <Link class="btn btn-cta-secondary" to={`/`}>Вернуться на главную</Link>
            </div>
        </section>
    )
}
export default LazernayaGravirovka;