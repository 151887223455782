/* eslint-disable jsx-a11y/anchor-is-valid */
import { React, useState } from 'react';
import uuid from 'react-uuid';
import { Link } from 'react-router-dom';

function id() {
    return uuid();
}

const products = [
    {
        id: id(),
        title: 'Информационные стенды',
        url: '/InformacionnyeCtendy/'
    },
    {
        id: id(),
        title: 'Свадебная атрибутика',
        url: '/SvadebnayaAtributika/'
    },
];


function MenuProducts() {

    const [product, setProduct] = useState(products);

    const result = product.map(produ => {

        return <ul class="list-unstyled" key={produ.id}>
            <li> <i class="fa fa-external-link"></i>  <Link to={produ.url}>{produ.title}</Link></li>
        </ul>

    })




    return (
        <aside class="credits aside section">
            <div class="section-inner">
                <h2 class="heading">Наши продукты</h2>
                <div class="content">

                    {result}


                    <hr />


                    <p>Рады сообщить вам, что мы постоянно работаем над улучшением нашего сайта и добавлением новых товаров. Вы также можете следить за нашими обновлениями в группе VK</p>
                    <a class="btn btn-cta-primary btn-download" href="https://vk.com/asanpro/" target="_blank" rel="noreferrer"><i class="fa fa-vk"></i>Посетить</a>
                </div>
            </div>
        </aside>
    )
}
export default MenuProducts;

