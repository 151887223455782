import React, {useEffect } from 'react';
import Intro from "./Component/Intro/Intro";
import Adress from "./Component/Sidebars/Adres";
import Header from "./Component/Header/Header";
import Services from "./Component/Pages/Services/Services";
import About from "./Component/Sidebars/About/About";
import Articles from "./Component/Pages/Articles/Articles";
import Footer from "./Component/Footer/Footer";
import Delivery from "./Component/Sidebars/Delivery/Delivery";
import Products from "./Component/Pages/Products/MenuProducts";
import Partners from "./Component/Sidebars/Partners/Partners";
import Requisites from "./Component/Sidebars/Requisites/Requisites";
import PhotoGalleryPreview from "./Component/Sidebars/PhotoGalleryPreview/PhotoGalleryPreview";
import MenyServis from "./Component/Pages/Services/MenyServis";






function App() {


useEffect(() => {
  document.title = 'Асан - Главная';
}, []);



  return (
    
      <body>
        <Header />
        <div className="container sections-wrapper">
          <div className="row">
            <div className="primary col-md-8 col-sm-12 col-xs-12">
              <Intro />
              <Services />
              <About />
              <Articles />
            </div>
            <div className="secondary col-md-4 col-sm-12 col-xs-12">
              <Adress />
              <MenyServis />
              <Products />
              <Delivery />
              <Partners />
              <PhotoGalleryPreview />
              <Requisites />
            </div>
          </div>
        </div>
        <Footer />
      </body>
   
  );
}

export default App;
