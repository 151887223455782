import { Link } from "react-router-dom";

function Frezer() {
  return (
    <>
      <div class="item featured text-center">
        <h3 class="title">Фрезерная резка</h3>
        <p class="summary">Размер рабочего поля станка 2000*3000*150</p>
        <div class="featured-image">
          <img
            class="img-responsive project-image"
            src="img/4844385a8fcc11efaa6442315e171248 (1).jpg"
            alt="project name"
          />
          <div class="ribbon">
            <div class="text">Новинка</div>
          </div>
        </div>

        <div class="desc text-left">
          <p>
            Рады сообщить Вам, что в нашей мастерской пополнился автопарк и
            появилось новое направление - фрезерная резка материалов. Размер
            рабочего поля станка 2000*3000*150 позволяет обрабатывать
            большинство материалов, представленных на рекламном рынке, в
            мебельном производстве, строительстве.
          </p>
        </div>

        <Link class="btn btn-cta-secondary" to={`/FrezernayaRezka/`}>
          Узнать больше
        </Link>
      </div>
      <hr class="divider" />
    </>
  );
}
export default Frezer;
