import React, { useEffect } from 'react';
import FormContainer from './FormContainer';
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Adress from "../Sidebars/Adres";
import Delivery from "../Sidebars/Delivery/Delivery"

function Form() {



        useEffect(() => {
            document.title = 'Асан - Форма обратной связи';
        }, []);
 



    return (
        <div>
            <body>
                <Header />
                <div class="container sections-wrapper">
                    <div class="row">
                        <div class="primary col-md-8 col-sm-12 col-xs-12">
                            <section class="latest section">
                                <div class="section-inner">
                                    <h2 class="heading">Форма обратной связи</h2>
                                    <FormContainer />

                                   

                                    <hr />
                                    <Link class="btn btn-cta-secondary" to={`/`}>
                                        Вернуться на главную
                                    </Link>
                                </div>
                            </section>
                        </div>
                        <div class="secondary col-md-4 col-sm-12 col-xs-12">
                            <Adress />
                            <Delivery />

                        </div>
                    </div>
                </div>

                <Footer />
            </body>
        </div>
    );

}

export default Form;




