import React from 'react';
import ReactDOM from 'react-dom/client';
import './Component/css/index.css';
import {createBrowserRouter,RouterProvider,} from 'react-router-dom';
import ErrorPage404 from './error-page-404';
import FrezernayaRezka from './Component/Pages/Services/Frezer/FrezernayaRezka';
import App from './App';
import LazernayaRezka from './Component/Pages/Services/LazernayaRezka/LazernayaRezka';
import LazernayaGravirovka from './Component/Pages/Services/LazernayaGravirovka/LazernayaGravirovka';
import PloternayaRezka from './Component/Pages/Services/PloternayaRezka/PloternayaRezka';
import RemontStankovChPU from './Component/Pages/Services/RemontStankovChPU/RemontStankovChPU';
import InformacionnyeCtendy from './Component/Pages/Products/InformacionnyeCtendy';
import SvadebnayaAtributika from './Component/Pages/Products/SvadebnayaAtributika';
import FullArticles from './Component/Pages/Articles/FullArticles';
import data from './Component/Data'; 
import { MyContext } from './Component/MyContext'; 
import LazernayaGravirovkaNaStekle from './Component/Pages/Articles/SinglArticles/LazernayaGravirovkaNaStekle';
import GravirovkaNaMetalle from './Component/Pages/Articles/SinglArticles/GravirovkaNaMetalle';
import VizitnicyCLogotipom from './Component/Pages/Articles/SinglArticles/VizitnicyCLogotipom';
import GravirovkaNaStekleLazerom from './Component/Pages/Articles/SinglArticles/GravirovkaNaStekleLazerom';
import LazernayaRezkaIGravirovkaNaBumage from './Component/Pages/Articles/SinglArticles/LazernayaRezkaIGravirovkaNaBumage';
import LazernayaRezkaOtkrytokGravirovkaTekstaNaBumage from './Component/Pages/Articles/SinglArticles/LazernayaRezkaOtkrytokGravirovkaTekstaNaBumage';
import Gallery from './Component/ImageGallery/Gallery';
import Form from './Component/Form/Form';
import Maps from './Component/Pages/Map/Maps';





const router = createBrowserRouter([
  {
    path: '/',
    element: <MyContext.Provider value={data.articles}><App /></MyContext.Provider>,
    errorElement: <ErrorPage404 />,
  }, 
  {
    path: 'FrezernayaRezka/',
    element: <MyContext.Provider value={data.title}> <FrezernayaRezka /></MyContext.Provider >,
  },
  {
    path: 'LazernayaRezka/',
    element: <LazernayaRezka />,
  },
  {
    path: 'LazernayaGravirovka/',
    element: <LazernayaGravirovka />,
  },
  {
    path: 'PloternayaRezka/',
    element: <PloternayaRezka />,
  },
  {
    path: 'RemontStankovChPU',
    element: <RemontStankovChPU />
  },
  {
    path: 'InformacionnyeCtendy',
    element: <MyContext.Provider value={data.informacionnyeCtendy}><InformacionnyeCtendy /></MyContext.Provider>
  },
  {
    path: 'SvadebnayaAtributika',
    element: <MyContext.Provider value={data.svadebnayaAtributika}><SvadebnayaAtributika /></MyContext.Provider>
  },
  {
    path: 'FullArticles',
    element: <><MyContext.Provider value={data.articles}><FullArticles /></MyContext.Provider></>,
   
  }, 
  {
    path: 'FullArticles/LazernayaGravirovkaNaStekle',
    element: <><MyContext.Provider value={data.articles}><LazernayaGravirovkaNaStekle /> </MyContext.Provider></>,

  },
  {
    path: 'FullArticles/GravirovkaNaMetalle',
    element: <><MyContext.Provider value={data.articles}><GravirovkaNaMetalle /> </MyContext.Provider></>,

  }, 
  {
    path: 'FullArticles/VizitnicyCLogotipom',
    element: <><MyContext.Provider value={data.articles}><VizitnicyCLogotipom /> </MyContext.Provider></>,

  }, 
  {
    path: 'FullArticles/GravirovkaNaStekleLazerom',
    element: <><MyContext.Provider value={data.articles}><GravirovkaNaStekleLazerom /> </MyContext.Provider></>,

  },
  {
    path: 'FullArticles/LazernayaRezkaIGravirovkaNaBumage',
    element: <><MyContext.Provider value={data.articles}><LazernayaRezkaIGravirovkaNaBumage /> </MyContext.Provider></>,

  },
  {
    path: 'FullArticles/LazernayaRezkaOtkrytokGravirovkaTekstaNaBumage',
    element: <><MyContext.Provider value={data.articles}><LazernayaRezkaOtkrytokGravirovkaTekstaNaBumage /> </MyContext.Provider></>,

  },
  {
    path: 'Gallery',
    element: <><MyContext.Provider value={data.images}><Gallery /></MyContext.Provider></>,

  },
  {
    path: 'Form',
    element: <><MyContext.Provider value={data.images}><Form/></MyContext.Provider></>,

  }, {
    path: 'Maps',
    element: <><MyContext.Provider value={data.maps}><Maps/></MyContext.Provider></>,

  },


]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

