import { React, useState, useContext, useEffect } from "react";
import Header from "../../../Header/Header";
import Adress from "../../../Sidebars/Adres";
import Footer from "../../../Footer/Footer";
import { Link } from "react-router-dom";
import MenuProducts from "../../Products/MenuProducts";
import MenyServis from "../../Services/MenyServis";
import { MyContext } from '../../../MyContext';

const text = {
    textAlign: 'right'
}

function GravirovkaNaStekleLazerom() {


    const name = useContext(MyContext);
    useEffect(() => {
        document.title = "АсАн " + articles[0].title;
    }, []);



    const [articles, setArticle] = useState(name.slice(3, 4));
    

    const result = articles.map((articl) => {
        return (
            <>
                <h2 class="heading">{articl.title}</h2>
                <div class="content" >
                    <img
                        class="img-responsive project-image img"
                        src={articl.src}
                        alt={articl.alt}
                    />
                    <hr />
                    <p>
                        {articl.text}                                    </p>
                </div>
                <h6 style={text}>{articl.data}</h6>
                <hr /></>
        );
    });

    return (
        <body>
            <Header />

            <div class="container sections-wrapper">
                <div class="row">
                    <div class="primary col-md-8 col-sm-12 col-xs-12">
                        <section class="about section">
                            <div class="section-inner">
                                {result}
                                <Link class="btn btn-cta-secondary" to={`/FullArticles/`}>
                                    Вернуться к статьям
                                </Link>
                            </div>

                        </section>
                    </div>
                    <div class="secondary col-md-4 col-sm-12 col-xs-12">
                        <Adress />
                        <MenyServis />
                        <MenuProducts />

                    </div>
                </div>
            </div>

            <Footer />
        </body>
    );
}
export default GravirovkaNaStekleLazerom;
