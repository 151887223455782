
import { React, useState } from 'react';
import uuid from 'react-uuid';
import { Link } from "react-router-dom";

function id() {
    return uuid();
}

const delivery = [
    {
        id: id(),
        title: 'Страны СНГ',
        bar: '20%',
        skill: 'Medium'
    },
    {
        id: id(),
        title: 'По России',
        bar: '80%',
        skill: 'Expert'
    },
    {
        id: id(),
        title: 'Выполнено',
        bar: '100%',
        skill: 'Professional'
    },
];




function Delivery (){

  

    const [deliverys, setdeliverys] = useState(delivery);

    const result = deliverys.map(deliver => {
        const bar = {
            width: deliver.bar,
        }
        return <div class="item">
            <h3 class="level-title">{deliver.title}<span class="level-label" data-toggle="tooltip" data-placement="left" data-animation="true" >{deliver.skill}</span></h3>
            <div class="level-bar">
                <div class="level-bar-inner" style={bar} data-level="96%">
                </div>
            </div>
        </div>

    })

    return (
        <aside class="skills aside section">
            <div class="section-inner">
                <h2 class="heading">Доставка</h2>
                <div class="content">
                    <p class="intro">
                        Транспортными компаниями мы доставляем готовую продукцию в оговоренные сроки нашим партнёрам.</p>

                    <div class="skillset">
                        {result}
                        
                        <Link class="more-link" to={`/Maps/`}><i class="fa fa-external-link"></i>Узнать больше</Link>
                    </div>
                </div>
            </div>
        </aside>
    )
}
export default Delivery;
