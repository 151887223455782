


function About (){
    return (
        <section class="experience section">
            <div class="section-inner">
                <h2 class="heading">О мастерской АсАн</h2>
                <div class="content">
                    <div class="item">
                        <h3 class="title">Лазерно-гравировальная мастерская АсАн - <span class="place">основана летом</span> <span class="year">(2013 года)</span></h3>
                        <p>Идеей создания предшествовал опыт работы в рекламно-производственных компаниях, где существовали ограничения. Индивидуальность не приветствовалась и оригинальные проекты так и не были воплощены в жизнь. Возникла идея создания собственной мастерской с воплощением в жизнь оригинальных идей, творческой составляющей, индивидуальным подходом к каждому клиенту.</p>
                    </div>
                    <aside class="testimonials aside section">
                        <div class="section-inner">
                                <div class="item">
                                    <blockquote class="quote">
                                        <p><i class="fa fa-quote-left"></i>Основной слоган компании - Мы ценим Ваше время.</p>
                                    </blockquote>
                                </div>
                            </div>
                    </aside>
                    <div class="item">
                        <p>Время-это бесценная валюта, потому что время- это наша жизнь. На первое место мы ставим качество производимой продукции, далее ценовой фактор, временная составляющая - просуммировав мы получаем оптимальное решение для наших клиентов. Клиентам мы предоставляем свободу выбора, а не ограничиваем однозначным решением.</p>
                    </div>

                </div>
            </div>
        </section>
    )
}
export default About;